
import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Print_Page from "./pages/Print_Page";
import QuotationForm1 from './pages/QuotationForm1';
import Final_quotation from "./pages/Final_quotation";
import UpdateServicesForm from "./pages/UpdateServicesForm";
import QuotationList from "./pages/QuotationList";
import NotesTable from "./pages/NotesTable";
import CreateNotes from "./pages/CreateNotes";
import DeleteNotes from "./pages/DeleteNotes";
import AddServices from "./pages/AddServices";
import ImageUploadComponent from "./pages/AddImageComponent";
import AddImageComponent from "./pages/AddImageComponent";
import CreateCompanyProfile from "./pages/CreateCompanyProfile";
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import { useSelector } from "react-redux";
import Reviews from "./pages/Reviews";
import DeleteCompanydata from "./pages/DeleteCompanydata";
import MainUpdateCompanyData from "./pages/MainUpdateCompanyData";
import UpdateCompanyData from "./pages/UpdateCompanyData";
import UpdateQuotationName from "./pages/UpdateQuotationName";
import UpdateNotes from './pages/UpdateNotes';
import CreateServicelist from "./pages/CreateServicelist";
import DeleteServiceName from "./pages/DeleteServiceName";
import UpdateServiceList from "./pages/UpdateServiceList";

import CreateInvoice from "./components/Invoice/CreateInvoice";
import Invoicelist from "./components/Invoice/Invoicelist";
import EditInvoiceName from "./components/Invoice/EditInvoiceName";
import FinalInvoice from "./components/Invoice/FinalInvoice";
import QuotationInvoice from "./components/Invoice/QuotationInvoice";
import AddInvoiceServices from "./components/Invoice/AddInvoiceServices";
import ReviewInvoice from "./components/Invoice/ReviewInvoice";
import PrintInvoice from './components/Invoice/PrintInvoice';
import CreateInvoiceProfile from "./components/Invoice/CreateInvoiceProfile";
import CreateInvoiceNotes from "./components/Invoice/CreateInvoiceNotes";
import DeleteInvoiceNotes from "./components/Invoice/DeleteInvoiceNotes";
import UpdateInvoiceNotes from "./components/Invoice/UpdateInvoiceNotes";
import EditInvoice_no from "./components/Invoice/EditInvoiceData/EditInvoice_no";
import EditInvoice_date from "./components/Invoice/EditInvoiceData/EditInvoice_date";
import EditInvoice_start_date from "./components/Invoice/EditInvoiceData/EditInvoice_start_date";
import EditInvoice_end_date from "./components/Invoice/EditInvoiceData/EditInvoice_end_date";
import ServicenameList from "./pages/ServicenameList";
import CreateCompanyProfile2 from "./pages/demo_quotation & Invoice/CreateCompanyProfile2";
import Login2 from "./pages/demo_quotation & Invoice/Login2";
import QuotationForm2 from "./pages/demo_quotation & Invoice/QuotationForm2";
import Reviews2 from "./pages/demo_quotation & Invoice/Reviews2";
import DeleteCompanydata2 from "./pages/demo_quotation & Invoice/DeleteCompanydata2";
import MainUpdateCompanyData2 from "./pages/demo_quotation & Invoice/MainUpdateCompanyData2";
import UpdateQuotationName2 from "./pages/demo_quotation & Invoice/UpdateQuotationName2";
import UpdateNotes2 from "./pages/demo_quotation & Invoice/UpdateNotes2";
import CreateServicelist2 from "./pages/demo_quotation & Invoice/CreateServicelist2";
import DeleteServiceName2 from "./pages/demo_quotation & Invoice/DeleteServiceName2";
import UpdateServiceList2 from "./pages/demo_quotation & Invoice/UpdateServiceList2";
import Final_quotation2 from "./pages/demo_quotation & Invoice/Final_quotation2";
import UpdateServicesForm2 from "./pages/demo_quotation & Invoice/UpdateServicesForm2";
import Print_Page2 from "./pages/demo_quotation & Invoice/Print_Page2";
import QuotationList2 from "./pages/demo_quotation & Invoice/QuotationList2";
import CreateNotes2 from "./pages/demo_quotation & Invoice/CreateNotes2";
import DeleteNotes2 from "./pages/demo_quotation & Invoice/DeleteNotes2";
import Addservices2 from "./pages/demo_quotation & Invoice/AddServices2";
import UpdateCompanyData2 from "./pages/demo_quotation & Invoice/UpdateCompanyData2";
import CreateInvoice2 from "./pages/demo_quotation & Invoice/Invoice/CreateInvoice2";
import Invoicelist2 from "./pages/demo_quotation & Invoice/Invoice/Invoicelist2";
import EditInvoiceName2 from "./pages/demo_quotation & Invoice/Invoice/EditInvoiceName2";
import FinalInvoice2 from "./pages/demo_quotation & Invoice/Invoice/FinalInvoice2";
import QuotationInvoice2 from "./pages/demo_quotation & Invoice/Invoice/QuotationInvoice2";
import AddInvoiceServices2 from "./pages/demo_quotation & Invoice/Invoice/AddInvoiceServices2";
import ReviewInvoice2 from "./pages/demo_quotation & Invoice/Invoice/ReviewInvoice2";
import PrintInvoice2 from "./pages/demo_quotation & Invoice/Invoice/PrintInvoice2";
import ServicenameList2 from "./pages/demo_quotation & Invoice/ServicenameList2";
import CreateInvoiceProfile2 from "./pages/demo_quotation & Invoice/Invoice/CreateInvoiceProfile2";
import CreateInvoiceNotes2 from "./pages/demo_quotation & Invoice/Invoice/CreateInvoiceNotes2";
import DeleteInvoiceNotes2 from "./pages/demo_quotation & Invoice/Invoice/DeleteInvoiceNotes2";
import UpdateInvoiceNotes2 from "./pages/demo_quotation & Invoice/Invoice/UpdateInvoiceNotes2";
import EditInvoice_no2 from "./pages/demo_quotation & Invoice/Invoice/EditInvoiceData/EditInvoice_no2";
import EditInvoice_date2 from "./pages/demo_quotation & Invoice/Invoice/EditInvoiceData/EditInvoice_date2";
import EditInvoice_start_date2 from "./pages/demo_quotation & Invoice/Invoice/EditInvoiceData/EditInvoice_start_date2";
import EditInvoice_end_date2 from "./pages/demo_quotation & Invoice/Invoice/EditInvoiceData/EditInvoice_end_date2";


 
function App() {

  const user = useSelector(state => state.auth.user);
  
  return (
    <>

<div className="" style={{overflow:"hidden"}}>

<Routes>
  
       
          {/* <Route path="/quotation-form" element={<QuotationForm1 />} /> */}
   
          <Route path="/quotation-form" element={user ? <QuotationForm1 /> : <Navigate to="/" />} />
          <Route path="/admin-register-doaguru-it-solution" element={<Registration />} />
          <Route path="/" element={user? <CreateCompanyProfile />:<Login/>} />
        

        <Route path="/final-quotation/:id" element={user ? <Final_quotation/>: <Navigate to="/" />} />
        <Route path="/update/:id" element={user ?<UpdateServicesForm />: <Navigate to="/" />} />
        <Route path="/print/:id" element={user ?<Print_Page />: <Navigate to="/" />} />
        <Route path="/quotationlist" element={user ? <QuotationList />: <Navigate to="/" />}/>
        <Route path="/createnotes/:id" element={user ? <CreateNotes />: <Navigate to="/" />}/>
        <Route path="/deletenotes/:id" element={user ? <DeleteNotes />: <Navigate to="/" />} />
        <Route path="/addservices/:id" element={user ? <AddServices />: <Navigate to="/" />} />
        <Route path="/addimage/:quotationId/:imageType" element={<AddImageComponent />} />
        <Route path="/create-company-profile" element={user ? <CreateCompanyProfile />: <Navigate to="/" />}  />
        <Route path="/review/:id" element={user ? <Reviews />: <Navigate to="/" />} />
        <Route path="/deletecompanydata" element={user ? <DeleteCompanydata />: <Navigate to="/" />} />
        <Route path="/mainupdatecompanydata" element={user ? <MainUpdateCompanyData />: <Navigate to="/" />} />
        <Route path="/updatecompanydata" element={user ? <UpdateCompanyData />: <Navigate to="/" />} />
        <Route path="/update-quotation-name/:id" element={user ? <UpdateQuotationName />: <Navigate to="/" />} />
        <Route path="/update-notes/:id" element={user ? <UpdateNotes />: <Navigate to="/" />} />
        <Route path="/create-servicelist" element={user ? <CreateServicelist />: <Navigate to="/" />} />
        <Route path="/delete-servicename" element={user ? <DeleteServiceName />: <Navigate to="/" />} />
        <Route path="/update-servicename" element={user ? <UpdateServiceList />: <Navigate to="/" />} />
        
        <Route path="/create-invoice" element={user ? <CreateInvoice />: <Navigate to="/" />} />
        <Route path="/invoicelist" element={user ? <Invoicelist />: <Navigate to="/" />} />
        <Route path="/update-invoice-name/:id" element={user ? <EditInvoiceName />: <Navigate to="/" />} />
        <Route path="/final-invoice/:id" element={user ? <FinalInvoice/>: <Navigate to="/" />} />
        <Route path="/quotation-invoice/:id" element={user ? <QuotationInvoice/>: <Navigate to="/" />} />
        <Route path="/addservicesinvoice/:id" element={user ? <AddInvoiceServices/>: <Navigate to="/" />} />
        <Route path="/review-invoice/:id" element={user ? <ReviewInvoice />: <Navigate to="/" />} />
        <Route path="/print-invoice/:id" element={user ?<PrintInvoice />: <Navigate to="/" />} />
        <Route path="/servicenamelist" element={user ? <ServicenameList/>: <Navigate to="/" />} />         

        <Route path="/invoice-profile" element={user ?<CreateInvoiceProfile />: <Navigate to="/" />} />

        <Route path="/invoicecreatenotes/:id" element={user ? <CreateInvoiceNotes />: <Navigate to="/" />}/>
        <Route path="/invoicedeletenotes/:id" element={user ? <DeleteInvoiceNotes />: <Navigate to="/" />} />
        <Route path="/invoice-update-notes/:id" element={user ? <UpdateInvoiceNotes />: <Navigate to="/" />} />


        <Route path="/update-invoice-number/:id" element={user ? <EditInvoice_no />: <Navigate to="/" />} />
        <Route path="/update-invoice-date/:id" element={user ? <EditInvoice_date/>: <Navigate to="/" />} />         
        <Route path="/update-invoice-start-date/:id" element={user ? <EditInvoice_start_date/>: <Navigate to="/" />} />         
        <Route path="/update-invoice-end-date/:id" element={user ? <EditInvoice_end_date/>: <Navigate to="/" />} />         
        
        

    {/* Demo Quotation Link */}

    <Route path="/quotation-form2" element={user ? <QuotationForm2 /> : <Navigate to="/demo" />} />
         
          <Route path="/demo" element={user? <CreateCompanyProfile2 />:<Login2/>} />
        

        <Route path="/final-quotation2/:id" element={user ? <Final_quotation2/>: <Navigate to="/demo" />} />
        <Route path="/update2/:id" element={user ?<UpdateServicesForm2 />: <Navigate to="/demo" />} />
        <Route path="/print2/:id" element={user ?<Print_Page2 />: <Navigate to="/demo" />} />
        <Route path="/quotationlist2" element={user ? <QuotationList2 />: <Navigate to="/demo" />}/>
        <Route path="/createnotes2/:id" element={user ? <CreateNotes2 />: <Navigate to="/demo" />}/>
        <Route path="/deletenotes2/:id" element={user ? <DeleteNotes2 />: <Navigate to="/demo" />} />
        <Route path="/addservices2/:id" element={user ? <Addservices2 />: <Navigate to="/demo" />} />

        <Route path="/create-company-profile2" element={user ? <CreateCompanyProfile2 />: <Navigate to="/demo" />}  />
        <Route path="/review2/:id" element={user ? <Reviews2 />: <Navigate to="/demo" />} />
        <Route path="/deletecompanydata2" element={user ? <DeleteCompanydata2 />: <Navigate to="/demo" />} />
        <Route path="/mainupdatecompanydata2" element={user ? <MainUpdateCompanyData2 />: <Navigate to="/demo" />} />
        <Route path="/updatecompanydata" element={user ? <UpdateCompanyData2 />: <Navigate to="/demo" />} />
        <Route path="/update-quotation-name2/:id" element={user ? <UpdateQuotationName2 />: <Navigate to="/demo" />} />
        <Route path="/update-notes2/:id" element={user ? <UpdateNotes2 />: <Navigate to="/demo" />} />
        <Route path="/create-servicelist2" element={user ? <CreateServicelist2 />: <Navigate to="/demo" />} />
        <Route path="/delete-servicename2" element={user ? <DeleteServiceName2 />: <Navigate to="/demo" />} />
        <Route path="/update-servicename2" element={user ? <UpdateServiceList2 />: <Navigate to="/demo" />} />
        
        <Route path="/create-invoice2" element={user ? <CreateInvoice2 />: <Navigate to="/demo" />} />
        <Route path="/invoicelist2" element={user ? <Invoicelist2 />: <Navigate to="/demo" />} />
        <Route path="/update-invoice-name2/:id" element={user ? <EditInvoiceName2 />: <Navigate to="/demo" />} />
        <Route path="/final-invoice2/:id" element={user ? <FinalInvoice2/>: <Navigate to="/demo" />} />
        <Route path="/quotation-invoice2/:id" element={user ? <QuotationInvoice2/>: <Navigate to="/demo" />} />
        <Route path="/addservicesinvoice2/:id" element={user ? <AddInvoiceServices2/>: <Navigate to="/demo" />} />
        <Route path="/review-invoice2/:id" element={user ? <ReviewInvoice2 />: <Navigate to="/demo" />} />
        <Route path="/print-invoice2/:id" element={user ?<PrintInvoice2 />: <Navigate to="/demo" />} />
        <Route path="/servicenamelist2" element={user ? <ServicenameList2/>: <Navigate to="/demo" />} />         

        <Route path="/invoice-profile2" element={user ?<CreateInvoiceProfile2 />: <Navigate to="/demo" />} />

        <Route path="/invoicecreatenotes2/:id" element={user ? <CreateInvoiceNotes2 />: <Navigate to="/demo" />}/>
        <Route path="/invoicedeletenotes2/:id" element={user ? <DeleteInvoiceNotes2 />: <Navigate to="/demo" />} />
        <Route path="/invoice-update-notes2/:id" element={user ? <UpdateInvoiceNotes2 />: <Navigate to="/demo" />} />


        <Route path="/update-invoice-number2/:id" element={user ? <EditInvoice_no2 />: <Navigate to="/demo" />} />
        <Route path="/update-invoice-date2/:id" element={user ? <EditInvoice_date2/>: <Navigate to="/demo" />} />         
        <Route path="/update-invoice-start-date2/:id" element={user ? <EditInvoice_start_date2/>: <Navigate to="/demo" />} />         
        <Route path="/update-invoice-end-date2/:id" element={user ? <EditInvoice_end_date2/>: <Navigate to="/demo" />} />         

        



      </Routes>
 
</div>

     
    </>
  );
}

export default App;
